<template>
  <div>
    <slot name="chat-window-header" />
    <v-list
      flat
      dense
      class="d-flex flex-column justify-end py-0 mx-auto rounded-b-lg drag-cancel"
      style="background-color: white;"
      :style="[vListStyle, vListPosition]"
    >
      <v-list-item-group
        class="d-flex flex-column-reverse"
        style="overflow-y: auto;"
        :style="'max-height: ' + maxHeight + ';'"
        id="list">
        <div v-if="isMessageLoading" class="pa-4">
          <div class="dot-flashing ml-4"></div>
        </div>
        <v-list-item-content
          v-for="(item, index) in chatItems"
          :key="index"
          class="pa-0"
          style="width: 100%; flex: 0 0 auto;"
        >
          <v-alert
            class="text-body-2 rounded-0 mb-0"
            :class="item.role === 'oneRecommendation' ? 'pb-0' : null"
            :color="item.role === 'user' ? 'grey lighten-4' : null"
            style="overflow-x: auto;"
          >
            <v-row dense no-gutters>
              <v-col cols="auto" class="mr-4">
                <v-img
                  :src="item.role === 'user' ? cdnUrl + '/assets/images/item/icon-chat-user.svg' : assistantIcon"
                  contain
                  width="24"
                  height="24"
                />
              </v-col>
              <v-col v-if="item.role === 'multipleRecommendation' && item.content.length && !item.isInherited">
                <p class="ma-0">おすすめの本がいくつか見つかりました。</p>
              </v-col>
              <v-col :class="item.role === 'multipleRecommendation' ? 'col-12' : null">
                <template v-if="item.role === 'oneRecommendation'">
                  <template v-if="item.layout !== 'list'">
                    <v-row dense no-gutters>
                      <v-col cols="auto" class="one-recommend-item">
                        <vb-item
                          :item="item.content[0]"
                          :key="item.content[0].vs_catalog_id"
                          layout="fixed-column"
                          fixed_width="120"
                          :is_price_label_size_x_small='true'
                          hide_title
                        />
                      </v-col>
                      <v-col tag="a" class="d-flex justify-center flex-column align-self-stretch pl-3"
                             :href="`/${item.content[0].title_for_url}/bp/${item.content[0].vs_catalog_id}`"
                             target="_blank">
                        <p class="font-weight-bold mb-2" style="color: black">{{ item.content[0].title }}</p>
                        <p class="black--text mb-0">{{ item.content[0].author }}</p>
                      </v-col>
                    </v-row>
                    <v-sheet width="120" class="mt-4 ml-2 ml-md-4">
                      <v-btn
                        class="d-block mb-1 mx-auto"
                        :class="{'is-disable': isRecommendReasonLoading}"
                        color="primary"
                        x-small
                        elevation="0"
                        @click="otherRecommendation(item.otherRecommendation);"
                      >他のおすすめの本</v-btn>
                    </v-sheet>
                  </template>
                  <template v-else>
                    <a :href="`/${item.content[0].title_for_url}/bp/${item.content[0].vs_catalog_id}`" target="_blank"
                       class="font-weight-bold black--text mb-1">
                      {{ item.content[0].title }}
                    </a>
                    <p class="black--text">{{ item.content[0].author }}</p>
                    <p class="black--text">{{ item.content[0].min_sell_price ? item.content[0].min_sell_price + '円' : '現在、この本の在庫はありません' }}</p>
                  </template>
                </template>
                <template v-else-if="item.role === 'multipleRecommendation' && item.content.length">
                  <slide-item
                    v-if="item.layout !== 'list'"
                    title=""
                    :items="item.content"
                    min-height="auto"
                    layout="row"
                  >
                    <template #actionButton="slotProps">
                      <v-btn
                        class="d-block mx-auto"
                        :class="{'is-disable': isRecommendReasonLoading}"
                        color="primary"
                        x-small
                        elevation="0"
                        @click="recommendedReason(slotProps.catalogId, item.question);"
                      >おすすめの理由</v-btn>
                    </template>
                  </slide-item>
                  <template v-else>
                    <v-list tag="ul" class="pt-4 px-4">
                      <v-list-item tag="li" v-for="(itemList, index) in item.content" :key="index">
                        <span class="col-auto align-self-baseline pa-0">{{ index + 1 }}.</span>
                        <v-list-item-content class="pt-1 pl-4">
                          <a :href="`/${itemList.title_for_url}/bp/${itemList.vs_catalog_id}`" target="_blank"
                             class="font-weight-bold black--text mb-1">
                            {{ itemList.title }}
                          </a>
                          <p class="black--text mb-1">{{ itemList.author }}</p>
                          <p class="black--text mb-0">{{ itemList.min_sell_price ? itemList.min_sell_price + '円' : '現在、この本の在庫はありません' }}</p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </template>
                <template v-else>
                  <div style="white-space: break-spaces;">{{ item.content.split(",").join("\n").trim() }}</div>
                </template>
              </v-col>
            </v-row>
          </v-alert>
        </v-list-item-content>

      </v-list-item-group>
      <v-divider class="my-0"></v-divider>
      <v-list-item-action class="ma-0 px-1" style="width: 100%">
        <validation-observer v-slot="{ invalid }" ref="observer" class="col-12 pa-0">
          <v-row dense no-gutters>
            <v-col>
              <validation-provider v-slot="{ errors }" rules="required|half_width_symbol" name="質問文">
                <v-textarea
                  v-model="userMessageInputValue"
                  solo
                  flat
                  full-width
                  hide-details
                  label="メッセージを入力"
                  style="border: 0 none"
                  rows="1"
                  no-resize
                  auto-grow
                ></v-textarea>
              </validation-provider>
            </v-col>
            <v-col cols="auto" class="d-flex align-center pr-1">
              <v-btn
                icon
                :style="isDisabled || invalid ? 'background-color: #cacaca !important;' : 'background-color: #009098 !important;'"
                class="rounded"
                @click="sendMessage();"
                width="40"
                height="40"
                :disabled="isDisabled || invalid"
              >
                <v-icon color="white" small style="color: white !important;">mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-list-item-action>
    </v-list>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import SlideItem from "../organisms/top/slide-item.vue";
import VbItem from "./vb-item.vue";
import searchConfig from "../../config/search.js";

const {mapState, mapGetters, mapActions, mapMutations} = createNamespacedHelpers('openAi');
export default {
  name: "vb-ai-chat",
  components: {VbItem, SlideItem},
  props: {
    absolute: {type: Boolean, default: true,},
    maxHeight: {type: String, default: '100%',},
    isExternal: {type: Boolean, default: false,},
    assistantMessage: {type: String, default: 'こんにちは。本のキーワードでも、あなたの今の気分でも大丈夫。探したい本について教えてください。'},
  },
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      chatItems: [
        {
          role: 'assistant',
          content: this.assistantMessage,
        },
      ],
      isMessageLoading: false,
      userMessageApiSendValue: null,
      isDisabled: false,
      recommendCandidateItems: [],
      functionCallArguments: {},
      isConversationContinues: false,
      latestDisplayItems: {
        role: null,
        content: null,
        question: null,
      },
      tagList: [],
      specialTags: ['本質本'],
      isRecommendReasonLoading: false,
      errorMessage: {
        'recommendReason': '申し訳ありません。この本のおすすめの理由を見つけられませんでした。',
        'recommendBook': '申し訳ありません。お探しの本は見つかりませんでした。\n質問内容を変更して再度お試しください。',
        'aiAnswer': '回答が取得できませんでした。\n質問内容を変更して再度お試しください。',
        'serverError': '申し訳ありません。エラーが発生しました。\n質問内容を変更して再度お試し頂くか、しばらくしてからもう一度お試しください。',
      },
    }
  },
  inject: {
    'sendEvent': {default: () => null},
  },
  methods: {
    ...mapMutations([
      'mutateSearchWord',
      'mutateKanaReadings',
      'mutateOtherReadings',
      'resetVectorSearchState',
      'mutateSelectedSellPrices',
      'mutateSelectedPublishDate',
      'applySearchResults',
      'mutateTags',
      'mutateSelectedSortCode',
      'mutateSelectedTopPopularity',
      'mutateNotSearchTagCategory',
      'setUserMessageInputValue'
    ]),
    ...mapActions([
      'research',
      'karteSearch',
      'searchItem',
    ]),
    scrollToBottom() {
      const list = document.getElementById('list');
      //スクロールバーの位置をリストの最下部に設定
      setTimeout(() => {
        list.scrollTo(0, list.scrollHeight);
      }, 300);
    },
    async sendMessage() {
      if (this.userMessageInputValue) {
        this.tagList = [];
        this.mutateTags(this.tagList);
        this.isMessageLoading = true;
        this.isDisabled = true;
        // やり取りが続いている場合は、前回の質問文を含めて送信する
        this.userMessageApiSendValue = this.isConversationContinues ? this.userMessageApiSendValue + "\n" + this.userMessageInputValue : this.userMessageInputValue;
        await this.addChatItems({role: 'user', content: this.userMessageInputValue});
        this.setUserMessageInputValue(null);
        // 質問文に特定キーワードが含まれる場合、タグ検索を行う
        const containsSpecificStringResult = this.containsSpecificString(this.userMessageApiSendValue);
        if (containsSpecificStringResult.length) {
          this.tagList = containsSpecificStringResult;
          await this.tagRecommendation();
        } else {
          this.retrieveAiAnswer();
        }
        this.scrollToBottom();
        this.sendEvent(this.userMessageApiSendValue);
      }
    },
    async searchExecution() {
      const keyword = this.userMessageApiSendValue;
      let tempKeyWords = this.buildTranscriptions(keyword);
      this.mutateSearchWord(keyword);
      this.mutateKanaReadings(JSON.stringify(tempKeyWords.kanaWords));
      this.mutateOtherReadings(JSON.stringify(tempKeyWords.others));
      this.resetVectorSearchState();

      this.mutateSelectedSortCode("2");
      this.mutateSelectedTopPopularity("5");
      this.mutateSelectedSellPrices([this.minPriceFilterValue, this.maxPriceFilterValue]);
      this.mutateSelectedPublishDate([this.minPublishDateFilterValue, this.maxPublishDateFilterValue]);

      if(this.getIsExternal){
        await this.research();
      }else{
        await this.karteSearch();
      }
    },
    async tagSearchExecution() {
      const keyword = '';
      let tempKeyWords = this.buildTranscriptions(keyword);
      this.mutateSearchWord(keyword);
      this.mutateKanaReadings(JSON.stringify(tempKeyWords.kanaWords));
      this.mutateOtherReadings(JSON.stringify(tempKeyWords.others));
      this.resetVectorSearchState();

      this.mutateTags(this.tagList);
      // タグでカテゴリー検索を行わない
      this.mutateNotSearchTagCategory(true);
      if(this.getIsExternal){
        await this.research();
      }else{
        await this.karteSearch();
      }
    },
    // function_callを実行する
    retrieveAiAnswer() {
      axios.post("/api/retrieveFunctionCall", {
        searchText: this.userMessageApiSendValue,
        ...(this.getIsExternal ? {isExternal: this.getIsExternal} : ''),
      }).then(async response => {
        if (response && response.data.type === 'interactive') {
          await this.addChatItems({role: 'assistant', content: response.data.content});
          this.isConversationContinues = true;
          this.isMessageLoading = false;
          this.isDisabled = false;
        } else if (response && response.data.type === 'function_call') {
          this.functionCallArguments = response.data.arguments;
          const functionName = response.data.functionName;
          if (typeof this[functionName] === 'function') {
            this[functionName]();
          }
          this.isConversationContinues = false;
        } else {
          this.errorHandler(this.errorMessage.aiAnswer);
          this.isConversationContinues = false;
          this.isMessageLoading = false;
          this.isDisabled = false;
        }
      }).catch((err) => {
        this.errorHandler(this.errorMessage.serverError);
        this.isMessageLoading = false;
        this.isDisabled = false;
      });
    },
    // おすすめの本を1冊及びその理由を取得
    retrieveRecommendedBook(numberOfItems = 1) {
      if (!this.recommendCandidateItems.length) {
        this.errorHandler(this.errorMessage.recommendBook);
        this.isMessageLoading = false;
        this.isDisabled = false;
        return;
      }
      axios.post("/api/retrieveRecommendedBook", {
        searchText: this.userMessageApiSendValue,
        recommendCandidateItems: this.recommendCandidateItems,
        numberOfItems: numberOfItems,
        ...(this.getIsExternal ? {isExternal: this.getIsExternal} : ''),
      }).then(async response => {
        if (response && response.data.vsCatalogId && response.data.reason) {
          const roleName = 'oneRecommendation';
          await this.addChatItems({
            role: roleName,
            content: this.createDisplaySearchItems(roleName, [{vs_catalog_id: response.data.vsCatalogId}]),
            layout: this.functionCallArguments.displayMethod ? this.functionCallArguments.displayMethod : 'grid',
            question: this.userMessageApiSendValue,
            otherRecommendation: this.recommendCandidateItems.concat()
          });
          await this.addChatItems({role: 'assistant', content: response.data.reason});
        } else {
          this.errorHandler(this.errorMessage.recommendBook);
        }
        this.isMessageLoading = false;
        this.isDisabled = false;
      }).catch((err) => {
        this.errorHandler(this.errorMessage.serverError);
        this.isMessageLoading = false;
        this.isDisabled = false;
      });
    },
    // おすすめの理由を取得
    retrieveRecommendedReason(catalogId, question) {
      function displayErrorMessage() {
        this.errorHandler(this.errorMessage.recommendReason);
        this.isRecommendReasonLoading = false;
        this.isMessageLoading = false;
      }

      if (!catalogId) {
        displayErrorMessage();
        return;
      }
      const foundItem = this.itemFoundFromHistory(catalogId);
      if (!foundItem) {
        displayErrorMessage();
        return;
      }

      axios.post("/api/retrieveRecommendedReason", {
        searchText: question,
        recommendItem: foundItem,
        numberOfItems: 1,
        ...(this.getIsExternal ? {isExternal: this.getIsExternal} : ''),
      }).then(async response => {
        if (response && response.data.reason) {
          const roleName = 'oneRecommendation';

          await this.addChatItems({
            role: roleName,
            content: [foundItem],
            layout: 'grid'
          });
          await this.addChatItems({role: 'assistant', content: response.data.reason});

        } else {
          this.errorHandler(this.errorMessage.recommendBook);
        }
        this.isMessageLoading = false;
        this.isDisabled = false;
        this.isRecommendReasonLoading = false;
      }).catch((err) => {
        this.errorHandler(this.errorMessage.serverError);
        this.isMessageLoading = false;
        this.isDisabled = false;
        this.isRecommendReasonLoading = false;
      });
    },
    // 文字列に複数の特定の文字列が含まれている場合、その文字列を配列で返す
    containsSpecificString(str) {
      let result = [];
      const tags = this.specialTags;
      const formattedString = this.fullWidthToHalfWidthAlphabet(str.toUpperCase());
      for (let tag of tags) {
        if (formattedString.indexOf(tag.toUpperCase()) !== -1) {
          result.push(tag);
        }
      }
      return result;
    },
    // 半角記号を全角に変換
    halfWidthToFullWidthSymbol(value) {
      return value.replace(/[!-\/:-@\[-`\{-~]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
      });
    },
    // 全角英字を半角に変換
    fullWidthToHalfWidthAlphabet(value) {
      return value.replace(/[Ａ-Ｚａ-ｚ]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    },
    // GPTに送るおすすめ候補の上位N件を不要なデータを除いてセット
    async setRecommendedCandidateItems(upperLimit = 10) {
      this.recommendCandidateItems = [];
      if (this.searchResults.length) {
        this.recommendCandidateItems = this.searchResults.slice(0, upperLimit).map((item) => {
          return item;
        });
      }
    },
    createDisplaySearchItems(roleName, selectedVsCatalogIds) {
      let result = [];
      result = this.searchResults.filter((candidateItem) => {
        return selectedVsCatalogIds.some((selectedIds) => {
          return candidateItem.vs_catalog_id === selectedIds.vs_catalog_id;
        });
      });

      this.latestDisplayItems.role = roleName;
      this.latestDisplayItems.content = result;
      this.latestDisplayItems.question = this.userMessageApiSendValue;
      return result;
    },
    // チャット欄にメッセージを追加
    async addChatItems(itemObject) {
      this.chatItems.unshift(itemObject);
    },
    // 複数のおすすめ本を表示
    async selectMultipleRecommendation() {
      const roleName = 'multipleRecommendation';
      await this.searchExecution();
      await this.setRecommendedCandidateItems();
      if (this.recommendCandidateItems.length) {
        const displayItems = this.recommendCandidateItems;
        await this.addChatItems({
          role: roleName,
          content: displayItems,
          layout: this.functionCallArguments.displayMethod ? this.functionCallArguments.displayMethod : 'grid',
          question: this.userMessageApiSendValue,
        });
        this.latestDisplayItems.role = roleName;
        this.latestDisplayItems.content = displayItems;
        this.latestDisplayItems.question = this.userMessageApiSendValue;
      } else {
        this.errorHandler(this.errorMessage.recommendBook);
      }

      this.isMessageLoading = false;
      this.isDisabled = false;
    },
    // おすすめ本を1冊表示
    async selectOneRecommendation() {
      await this.searchExecution();
      await this.setRecommendedCandidateItems();
      this.retrieveRecommendedBook();
    },
    // タグ検索（特定のキーワードが質問文に含まれている場合）
    async tagRecommendation() {
      const roleName = 'multipleRecommendation';
      await this.tagSearchExecution();
      await this.setRecommendedCandidateItems();
      if (this.recommendCandidateItems.length) {
        const displayItems = this.recommendCandidateItems;
        await this.addChatItems({
          role: roleName,
          content: displayItems,
          layout: 'grid',
          question: this.userMessageApiSendValue,
        });
        this.latestDisplayItems.role = roleName;
        this.latestDisplayItems.content = displayItems;
        this.latestDisplayItems.question = this.userMessageApiSendValue;
      } else {
        this.errorHandler(this.errorMessage.recommendBook);
      }

      this.isMessageLoading = false;
      this.isDisabled = false;
    },
    // 検索結果の表示方法を変更
    displayMethodChange() {
      const roleName = this.latestDisplayItems.role;
      // 一度表示した検索結果を再度表示する場合は、latestDisplayItems.contentを使う
      this.addChatItems({
        role: roleName,
        content: this.createDisplaySearchItems(roleName, this.latestDisplayItems.content),
        layout: this.functionCallArguments.displayMethod ? this.functionCallArguments.displayMethod : 'grid',
        isInherited: true,
      });
      this.isMessageLoading = false;
      this.isDisabled = false;
      this.scrollToBottom();
    },
    // 他のおすすめの本を表示
    async otherRecommendation(otherRecommendItems = []) {
      this.isRecommendReasonLoading = true;
      const roleName = 'multipleRecommendation';
      const displayItems = otherRecommendItems.length ? otherRecommendItems : this.recommendCandidateItems;
      if (displayItems.length) {
        await this.addChatItems({
          role: roleName,
          content: displayItems,
          layout: 'grid',
          question: this.userMessageApiSendValue,
        });
        this.latestDisplayItems.role = roleName;
        this.latestDisplayItems.content = displayItems;
      } else {
        this.errorHandler(this.errorMessage.recommendBook);
      }
      this.isMessageLoading = false;
      this.isDisabled = false;
      this.isRecommendReasonLoading = false;
      this.scrollToBottom();
    },
    // おすすめの理由を取得して表示
    recommendedReason(catalogId, question) {
      this.isMessageLoading = true;
      this.isRecommendReasonLoading = true;
      this.retrieveRecommendedReason(catalogId, question);
      this.scrollToBottom();
    },
    // チャット欄での検索結果の中からカタログIDに一致するものを取得
    itemFoundFromHistory(catalogId) {
      // 必要なデータのみを抽出
      const refineResult = this.chatItems.filter((chat) => chat.role === 'oneRecommendation' || chat.role === 'multipleRecommendation').map((items) => {
        return items.content
      })
      // 重複を除いた配列を生成
      const uniqueData = Array.from(new Set(refineResult.flat(1).map(JSON.stringify))).map(JSON.parse);
      // ユニークなデータを1つの配列にまとめる
      const mergedData = uniqueData.reduce((acc, item) => {
        const existingItem = acc.find(i => i.vs_catalog_id === item.vs_catalog_id);
        if (!existingItem) {
          acc.push(item);
        }
        return acc;
      }, []);

      const foundItem = mergedData.find(item => item.vs_catalog_id === catalogId);
      return foundItem ? foundItem : null;
    },
    errorHandler(errorMessage) {
      this.addChatItems({
        role: 'assistant',
        content: errorMessage
      });
    },
    buildTranscriptions(text) {
      text = text.replace(/^(著者|出版社):/,"");
      let kanaText = this.convertRomanToKana(text);
      let kanaWords = [];
      let others = [];
      kanaText.split(/\s+/).forEach(function(keyword) {
        if (keyword.match(/^[ぁ-んァ-ヶー\s0-9０-９]+$/)) {//変換対象からローマ字外した
          // if (keyword.match(/^[ぁ-んァ-ヶー\sa-zA-Z0-9Ａ-Ｚａ-ｚ０-９]+$/)) {
          kanaWords.push(keyword);
        } else {
          others.push(keyword);
        }
      });
      let keywords = {'kanaWords': kanaWords,'others': others}

      return keywords;
    },
    convertRomanToKana(original) {
      const tree = searchConfig.TREE;
      const str = original.replace(/[Ａ-Ｚａ-ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).toLowerCase(); // 全角→半角→小文字
      let result = '';
      let tmp = '';
      let index = 0;
      const len = str.length;
      let node = tree;
      const push = (char, toRoot = true) => {
        result += char;
        tmp = '';
        node = toRoot ? tree : node;
      };
      while (index < len) {
        const char = str.charAt(index);
        push(tmp + char);
        index++;
      }

      tmp = tmp.replace(/n$/, 'ン'); // 末尾のnは変換する
      push(tmp);
      return result;
    },
  },
  computed: {
    ...mapGetters([
      'getSearchResults',
      'getVectorSearchResults',
      'getIsComplete',
      'getUserMessageInputValue',
      'getIsExternal',
    ]),
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    minPriceFilterValue() {
      return this.functionCallArguments.minPrice ? this.functionCallArguments.minPrice : VueConfig.search.MIN_NO_SPECIFIED_VALUE;
    },
    maxPriceFilterValue() {
      return this.functionCallArguments.maxPrice ? this.functionCallArguments.maxPrice : VueConfig.search.MAX_NO_SPECIFIED_VALUE;
    },
    minPublishDateFilterValue() {
      return this.functionCallArguments.minPublishDate ? this.functionCallArguments.minPublishDate : VueConfig.search.MIN_NO_SPECIFIED_VALUE;
    },
    maxPublishDateFilterValue() {
      return this.functionCallArguments.maxPublishDate ? this.functionCallArguments.maxPublishDate : VueConfig.search.MAX_NO_SPECIFIED_VALUE;
    },
    whetherToApplyVectorSearch() {
      return this.getIsComplete && this.getVectorSearchResults.length > 0;
    },
    searchResults() {
      return this.getSearchResults;
    },
    userMessageInputValue: {
      get() {
        return this.getUserMessageInputValue;
      },
      set(value) {
        if(value) {
          this.setUserMessageInputValue(this.halfWidthToFullWidthSymbol(value));
        }
      }
    },
    vListStyle() {
      let styles = {};
      if(this.smAndDown) {
        styles = {
          bottom: '4px',
          left: '4px',
          right: '4px',
          height: 'calc(100% - (60px))',
        }
      } else {
        styles = {
          bottom: '0',
          left: '0',
          right: '0',
          height: 'calc(100% - (57px + 8px))',
        }
      }
      return styles;
    },
    vListPosition() {
      return this.absolute ? {position: 'absolute'} : null;
    },
    assistantIcon() {
      if(this.isExternal) {
          return this.cdnUrl + '/assets/images/item/sozoboxlogo_blackborder.png';
      }
      return this.cdnUrl + '/assets/images/item/icon-chat-vb.svg';
    },
  },
  watch: {
    'whetherToApplyVectorSearch': function (value) {
      // 通常検索結果が取得済みかつベクトル検索結果が1件以上の場合、ベクター検索結果の1ページ目を適用
      if (value) {
        this.applySearchResults(this.getVectorSearchResults[0]);
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-textarea {
    textarea {
      border: 0 none;
    }
  }
  .v-skeleton-loader.massage-loading {
    min-height: 8px;
    .v-skeleton-loader__avatar {
      width: 8px;
      height: 8px;
    }
    .v-skeleton-loader__bone::after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    }
  }
  .one-recommend-item {
    a {
      margin-bottom: 0 !important;
    }
    .price-by-sell-buy-fixed-column {
      bottom: 0 !important;
    }
  }
  .v-progress-circular {
    width: 14px !important;
    height: 14px !important;
  }
  .v-btn {
    &:before {
      opacity: 0 !important;
    }
    &.is-disable {
      pointer-events: none !important;
    }
  }
  .slide-content {
    margin-bottom: 16px !important;
  }
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #eee;
  color: #eee;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #eee;
  color: #eee;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #eee;
  color: #eee;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}
@keyframes dot-flashing {
  0% {
    background-color: #eee;
  }
  50%, 100% {
    background-color: rgba(49, 46, 59, 0.2);
  }
}
</style>